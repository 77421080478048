@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

/* base styles */
body {
  font-family: Poppins, sans-serif;
  margin: 0;
  font-size: 1.1em;
}
ul, li, p, h1, h2, h3, h4 {
  margin: 0;
  padding: 0;
}
ul {
  list-style-type: none;
}
.btn {
  background: none;
  border: 2px solid #1db6a7;
  padding: 6px 12px;
  border-radius: 4px;
  color: #179387;
  font-weight: bold;
  cursor: pointer;
  font-size: 1em;
}
.btn:hover {
  background: #1ebdad;
  color: #fff;
}
.btn-delete {
  background: none;
  border: 2px solid #b30000;
  padding: 6px 12px;
  margin-right: 15px;
  border-radius: 4px;
  color: #b30000;
  font-weight: bold;
  cursor: pointer;
  font-size: 1em;
}
.btn-delete:hover {
  background: #b30000;
  color: #fff;
}
form {
  max-width: 360px;
  margin: 30px auto;
  padding: 20px;
}
.form-label {
  display: block;
  margin: 30px auto;
}
form span {
  display: block;
  margin-bottom: 6px;
}
form input {
  padding: 8px 6px;
  font-size: 1em;
  color: #777;
  width: 100%;
}

@media only screen and (max-width: 600px) {
  body {
    font-family: Poppins, sans-serif;
    margin: 0;
    font-size: unset;
  }
}
