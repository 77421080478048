.auth-form {
  max-width: 360px;
  margin: 60px auto;
  padding: 40px;
  border: 1px solid #ddd;
  box-shadow: 10px 1px 15px rgba(0,0,0,0.38);
  background: #fff;
}
.auth-form {
  max-width: 360px;
  margin: 60px auto;
  padding: 20px 60px;
}
.auth-form label {
  display: block;
  margin: 30px auto;
}
.auth-form span {
  text-align: left;
  display: block;
  margin-bottom: 6px;
}
.auth-form input {
  padding: 8px 6px;
  font-size: 1em;
  color: #777;
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .auth-form {
    max-width: 360px;
    margin: 60px auto;
    padding: 40px;
    border: 1px solid #ddd;
    box-shadow: 10px 1px 15px rgba(0,0,0,0.08);
    background: #fff;
  }
}
