.App {
  display: flex;
    /*padding-bottom: 50px;*/
}
.App .container {
  flex-grow: 1;
  padding: 0 60px 60px 60px;
}

@media only screen and (max-width: 600px) {
   .App {
    display: block;
  }
  .App .container {
  flex-grow: 1;
  padding: 0 20px 60px 20px;
}

}