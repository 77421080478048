.project-details {
  display: grid;
  grid-template-columns: 3fr 2fr;
  align-items: start;
  grid-gap: 60px;
}
.project-details .project-summary .late {
  color: #b30000;
}

#comment-form {
  margin: 0 auto 20px auto
}
/* project summary */
.project-summary {
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.05);
  background-color: #fff;
  /*padding: 16px;*/
  margin: 0px 0;
  border-radius: 6px;
}

.project-summary .due-date {
  margin: 10px 0;
  font-size: 0.9em;
  color: var(--title-color);
}
.project-summary .details {
  margin: 30px 0;
  color: var(--text-color);
  line-height: 1.8em;
  font-size: 0.9em;
}
.project-summary h4 {
  color: var(--text-color);
  font-size: 0.9em;
}
.project-summary .assigned-users {
  display: flex;
  margin-top: 20px;
}
.project-summary .assigned-users .avatar {
  margin-right: 10px;
}
.project-summary + .btn {
  margin-top: 20px;
}

/* project comments */
.project-comments label {
  margin-bottom: 0px;
}
.project-comments textarea {
  min-height: 40px;
  font-size: 1.1em;
}

/* comment list */
.project-comments h4 {
  color: var(--heading-color);
}
.project-comments li {
  padding: 16px;
  border-radius: 4px;
  border: 1px solid #f2f2f2;
  margin-top: 20px;
  box-shadow: 3px 3px 5px rgba(0,0,0,0.05);
  background: #fff;
}
.comment-author {
  display: inline-block;
  align-items: center;
  color: var(--title-color);
}
.comment-author .author-container {
  display: flex;
  align-items: flex-start;
  color: var(--title-color);
  width: 100%;
}
.comment-author .avatar {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.comments-date p {
  color: var(--text-color);
  font-size: 0.6em;
  margin-bottom: 10px;
  font-style: italic;

}
.comment-content {
  color: var(--text-color);
  font-size: 0.9em;
  padding: 10px;
}

@media only screen and (max-width: 600px) {
  .project-details {
    display: block;
  }
  .project-summary {
    margin: 20px 0 40px 0;
  }
}