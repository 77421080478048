@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);
/* base styles */
body {
  font-family: Poppins, sans-serif;
  margin: 0;
  font-size: 1.1em;
}
ul, li, p, h1, h2, h3, h4 {
  margin: 0;
  padding: 0;
}
ul {
  list-style-type: none;
}
.btn {
  background: none;
  border: 2px solid #1db6a7;
  padding: 6px 12px;
  border-radius: 4px;
  color: #179387;
  font-weight: bold;
  cursor: pointer;
  font-size: 1em;
}
.btn:hover {
  background: #1ebdad;
  color: #fff;
}
.btn-delete {
  background: none;
  border: 2px solid #b30000;
  padding: 6px 12px;
  margin-right: 15px;
  border-radius: 4px;
  color: #b30000;
  font-weight: bold;
  cursor: pointer;
  font-size: 1em;
}
.btn-delete:hover {
  background: #b30000;
  color: #fff;
}
form {
  max-width: 360px;
  margin: 30px auto;
  padding: 20px;
}
.form-label {
  display: block;
  margin: 30px auto;
}
form span {
  display: block;
  margin-bottom: 6px;
}
form input {
  padding: 8px 6px;
  font-size: 1em;
  color: #777;
  width: 100%;
}

@media only screen and (max-width: 600px) {
  body {
    font-family: Poppins, sans-serif;
    margin: 0;
    font-size: unset;
  }
}

.App {
  display: flex;
    /*padding-bottom: 50px;*/
}
.App .container {
  flex-grow: 1;
  padding: 0 60px 60px 60px;
}

@media only screen and (max-width: 600px) {
   .App {
    display: block;
  }
  .App .container {
  flex-grow: 1;
  padding: 0 20px 60px 20px;
}

}
.Navbar_navbar__1XEew {
  width: 100%;
  padding: 30px 0;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.Navbar_navbar__1XEew ul {
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: flex-end;
}
.Navbar_navbar__1XEew .Navbar_logo__35s_i {
  font-weight: bold;
  color: black;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  margin-right: auto;
}
.Navbar_navbar__1XEew .Navbar_logo__35s_i img {
  margin-right: 10px;
  -webkit-filter: invert(25%);
          filter: invert(25%);
  width: 36px;
  margin-top: -8px;
}
.Navbar_navbar__1XEew a {
  color: #333;
  text-decoration: none;
  margin-right: 20px;
}

@media only screen and (max-width: 600px) {
  .Navbar_navbar__1XEew {
    border-bottom: 1px solid #179387;
  }
}
.project-list {
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-gap: 20px;
}
#project-dates p {
  color: var(--text-color);
  font-size: 0.7em;
  /*margin: 4px 0 10px;*/
  font-style: italic;
}
#project-dates p span{
  font-weight: bold;
}
.project-list a {
  background-color: #fff;
  padding: 16px;
  border-radius: 6px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.05);
  text-decoration: none;
  color: inherit;
}
.project-list h4 {
  font-size: 0.9em;
  color: var(--heading-color);
}
.project-list p {
  color: var(--text-color);
  font-size: 0.9em;
}
.project-list .date {
  margin-top: 20px;
  padding-top: 10px;
  border-top: 1px solid #eee;
}
.project-list ul {
  margin: 10px 0;
  display: flex;
}
.project-list li {
  margin-right: 10px;
}
.project-list .avatar {
  width: 30px;
  height: 30px;
}
#project-dates .late {
  color: #b30000;
}
#project-dates .today {
  color: #f5c71a;
}
.completed a {
  background-color: #FC9790;
}
.completed-header{
  display: block;
  margin-top: 60px;
}

.project-details {
  display: grid;
  grid-template-columns: 3fr 2fr;
  align-items: start;
  grid-gap: 60px;
}
.project-details .project-summary .late {
  color: #b30000;
}

#comment-form {
  margin: 0 auto 20px auto
}
/* project summary */
.project-summary {
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.05);
  background-color: #fff;
  /*padding: 16px;*/
  margin: 0px 0;
  border-radius: 6px;
}

.project-summary .due-date {
  margin: 10px 0;
  font-size: 0.9em;
  color: var(--title-color);
}
.project-summary .details {
  margin: 30px 0;
  color: var(--text-color);
  line-height: 1.8em;
  font-size: 0.9em;
}
.project-summary h4 {
  color: var(--text-color);
  font-size: 0.9em;
}
.project-summary .assigned-users {
  display: flex;
  margin-top: 20px;
}
.project-summary .assigned-users .avatar {
  margin-right: 10px;
}
.project-summary + .btn {
  margin-top: 20px;
}

/* project comments */
.project-comments label {
  margin-bottom: 0px;
}
.project-comments textarea {
  min-height: 40px;
  font-size: 1.1em;
}

/* comment list */
.project-comments h4 {
  color: var(--heading-color);
}
.project-comments li {
  padding: 16px;
  border-radius: 4px;
  border: 1px solid #f2f2f2;
  margin-top: 20px;
  box-shadow: 3px 3px 5px rgba(0,0,0,0.05);
  background: #fff;
}
.comment-author {
  display: inline-block;
  align-items: center;
  color: var(--title-color);
}
.comment-author .author-container {
  display: flex;
  align-items: flex-start;
  color: var(--title-color);
  width: 100%;
}
.comment-author .avatar {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.comments-date p {
  color: var(--text-color);
  font-size: 0.6em;
  margin-bottom: 10px;
  font-style: italic;

}
.comment-content {
  color: var(--text-color);
  font-size: 0.9em;
  padding: 10px;
}

@media only screen and (max-width: 600px) {
  .project-details {
    display: block;
  }
  .project-summary {
    margin: 20px 0 40px 0;
  }
}
.avatar {
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
}
.avatar img {
    width: 100%;
    height: 100%;
}
.signup_auth-form__gTqLT {
  max-width: 360px;
  margin: 60px auto;
  padding: 40px;
  border: 1px solid #ddd;
  box-shadow: 10px 1px 15px rgba(0,0,0,0.38);
  background: #fff;
}
.signup_auth-form__gTqLT {
  max-width: 360px;
  margin: 60px auto;
  padding: 20px 60px;
}
.signup_auth-form__gTqLT label {
  display: block;
  margin: 30px auto;
}
.signup_auth-form__gTqLT span {
  text-align: left;
  display: block;
  margin-bottom: 6px;
}
.signup_auth-form__gTqLT input {
  padding: 8px 6px;
  font-size: 1em;
  color: #777;
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .signup_auth-form__gTqLT {
    max-width: 360px;
    margin: 60px auto;
    padding: 40px;
    border: 1px solid #ddd;
    box-shadow: 10px 1px 15px rgba(0,0,0,0.08);
    background: #fff;
  }
}

.sidebar {
  width: 220px;
  min-width: 220px;
  background: turquoise;
  min-height: 100vh;
  box-sizing: border-box;
  position: relative;
  color: #fff;
}
.smallSidebar {
  font-size: .75em;
}
.sidebar-content {
  position: fixed;
  width: inherit;
}
.sidebar .user {
  font-weight: bold;
  text-align: center;
  letter-spacing: 1px;
  padding: 40px 30px;
  border-bottom: 1px solid rgba(255,255,255,0.2);
}
.sidebar .links {
  margin-top: 50px;
  margin-left: 20px;
}
.sidebar .links li {
  margin-top: 10px;
}
.sidebar .links a {
  display: flex;
  padding: 10px;
  text-decoration: none;
  width: 100%;
  color: #fff;
  box-sizing: border-box;
  font-weight: bold;
  font-size: 1.2em;
}
.sidebar .links img {
  margin-right: 10px;
  -webkit-filter: invert(1);
          filter: invert(1);
  height: 30px;
  color: white;
}

.sidebar .links a.active {
  color: black;
  background: white;
  border-radius: 20px 0 0 20px;
  font-weight: normal;
}
.sidebar .links a.active img {
  /*filter: invert(1);*/
}
.sidebar .links .active img {
  -webkit-filter: invert(40%);
          filter: invert(40%);
}

@media only screen and (max-width: 600px) {
  .sidebar .links {
    margin-top: 20px;
    margin-left: 20px;
  }
   .sidebar .links a{
    display: inline;
  }
  .sidebar .user {
    display: none;
  }
  .sidebar {
    width: unset;
    min-height: 90px;
  }
  .sidebar .links a.active {
    border-radius: 20px
  }
  .sidebar .links .li {
    margin-top: 0;
  }
  .sidebar-content {
    position: absolute;
  }
}
.category-list li {
    display: inline-block;
    padding: 2px 7px;
}
.category-list {
    margin: 5px;
}
.category-list li:hover{
    text-decoration: underline;
    cursor: pointer;
}
